import React from "react";
import styled from "@emotion/styled";

const StyledHeaderBurger = styled.div`
  margin: 0;
  padding: 12px 12px 12px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 11;
  grid-column: 2/3;
  align-self: center;
  justify-self: end;
  cursor: pointer;
  z-index: 999;

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    display: none;
  }

  .burger__line {
    width: 20px;
    height: 1.4px;
    background: ${(props) => props.theme.colors.white};
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    display: block;

    &:first-of-type {
      transform: rotate(${(props) => (props.isMenuOpen ? "0" : "0")});
      position: relative;
      top: ${(props) => (props.isMenuOpen ? "10px" : "0")};
      opacity: ${(props) => (props.isMenuOpen ? "0" : "1")};
    }

    &:nth-of-type(2) {
      transform: rotate(${(props) => (props.isMenuOpen ? "-135deg" : "0")});
      position: relative;
      top: ${(props) => (props.isMenuOpen ? "6px" : "5px")};
    }

    &:last-of-type {
      transform: rotate(${(props) => (props.isMenuOpen ? "-45deg" : "0")});
      position: relative;
      top: ${(props) => (props.isMenuOpen ? "5px" : "10px")};
    }
  }
`;

const HeaderBurger = ({ toggleNav, isMenuOpen }) => {
  return (
    <StyledHeaderBurger isMenuOpen={isMenuOpen} onClick={() => toggleNav()}>
      <span className="burger__line" />
      <span className="burger__line" />
      <span className="burger__line" />
    </StyledHeaderBurger>
  );
};

export default HeaderBurger;
