import React from "react";
import { Global } from "@emotion/react";
import { Helmet } from "react-helmet";
import { globalStyles } from "../../styles/globalStyles.js";
import Header from "../Header/index.js";
import Footer from "./Footer.js";

import navigation from "../../data/navigation.js";

const Layout = ({ children }) => {
  return (
    <>
      <Global styles={globalStyles} />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <html lang="fr" />
      </Helmet>
      <Header items={navigation} navPosition="right" />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
