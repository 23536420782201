import PregnantWoman from "../images/anciennes/Fotolia_81347555_M.jpg";
import FootprintsInTheSand from "../images/anciennes/Fotolia_57295439_M.jpg";
import Swimmer from "../images/anciennes/Fotolia_14924973_M.jpg";
import Bad from "../images/anciennes/Fotolia_100411286_M.jpg";

const navigation = [
  // {
  //   id: "1",
  //   name: "Home",
  //   slug: "/",
  // },
  {
    id: "2",
    name: "La sophrologie",
    slug: "/la-sophrologie-caycedienne",
  },
  {
    id: "3",
    name: "Mon objectif",
    slug: "/mon-objectif",
  },
  {
    id: "4",
    name: "Accompagnement",
    slug: "/avancer-dans-la-vie-en-toute-serenite",
    subMenu: [
      {
        name: `Avancer dans la vie en toute sérénité`,
        link: `/avancer-dans-la-vie-en-toute-serenite`,
        image: FootprintsInTheSand,
        alt: "Femme sereine les pieds dans le sable",
      },
      {
        name: `Se préparer à la maternité`,
        link: `/se-preparer-a-la-maternite`,
        image: PregnantWoman,
        alt: "Femme enceinte en préparation à la maternité",
      },
      {
        name: `réussir une compétition, un examen`,
        link: `/reussir-une-competition-un-examen`,
        image: Swimmer,
        alt: "Nageur en prépartion pour une compétition",
      },
      {
        name: `Surmonter une période difficile`,
        link: `/surmonter-une-periode-difficile`,
        image: Bad,
        alt: "Personne qui surmonte une période difficile",
      },
    ],
  },
  {
    id: "5",
    name: "Entreprise",
    slug: "/la-sophrologie-en-entreprise",
  },
  {
    id: "6",
    name: "Les séances",
    slug: "/les-seances",
  },
  {
    id: "7",
    name: "Blog",
    slug: "/blog",
  },
  {
    id: "8",
    name: "Contact",
    slug: "/contact",
  },
];

export default navigation;
