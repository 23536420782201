import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

const StyledHeaderLogo = styled.div`
  grid-column: 1/2;
  align-self: center;
  z-index: 11;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    grid-column: 1/1;
    align-self: end;
  }
  .headerLogo__inner {
    text-decoration: none;
    color: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: left;
    line-height: 1.4;
    font-size: 2.8vw;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      font-size: 0.8vw;
      letter-spacing: 0.05vw;
    }
    h1 {
      font-family: ${(props) => props.theme.fonts.headings};
      margin-left: 1rem;
      em {
        color: ${(props) => props.theme.colors.white};
        font-family: ${(props) => props.theme.fonts.headingsItalic};
      }
    }
  }

  svg {
    height: 42px;
    width: auto;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      height: 48px;
      width: auto;
    }
  }
`;

const HeaderLogo = () => {
  // const theme = useTheme();
  return (
    <StyledHeaderLogo>
      <Link to="/" className="headerLogo__inner">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 59.528 48.189"
        >
          <g
            fill="none"
            stroke="#fff"
            strokeWidth="1.4"
            strokeLinejoin="round"
            strokeMiterlimit="10"
          >
            <path
              d="M26.858,43.226c-6.288-6.23-9.369-16.381-7.221-25.407c-5.002-2.807-11.413-4.032-17.326-3.129
		c-2.397,15.597,9.351,30.291,24.829,31.587"
            />
            <path
              d="M30.427,28.477c2.384-4.206,5.833-7.726,9.984-10.194c-1.372-6.714-5.172-12.542-10.44-16.533
		c-5.146,3.899-8.884,9.552-10.334,16.069C24.148,20.3,27.89,24.001,30.427,28.477z"
            />
            <path
              d="M40.411,18.283c4.795-2.892,10.886-4.347,16.758-3.756c2.938,17.591-12.63,33.713-30.028,31.75
		C25.394,35.183,30.86,23.873,40.411,18.283"
            />
          </g>
        </svg>
        <h1>
          Maureen Augras
          <br />
          <em>Sophrologue à Lille</em>
        </h1>
      </Link>
    </StyledHeaderLogo>
  );
};

export default HeaderLogo;
