import React, { useLayoutEffect, useRef } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const StyledHeaderNav = styled.nav`
  position: relative;
  display: block;
  @media screen and (max-width: ${(props) => props.theme.responsive.medium}) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: ${(props) => props.theme.colors.lightGreen};
    /* pointer-events: none; */
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    z-index: 9;
    opacity: 0;
    &.open {
      /* pointer-events: auto; */
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    place-self: center;
    /* pointer-events: auto; */
    justify-self: end;
    align-self: end;
  }

  a {
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    position: relative;
    text-transform: capitalize;
    font-family: ${(props) => props.theme.fonts.headingsItalic};
    font-size: 30px;
    line-height: 50px;
    letter-spacing: 0.15vw;
    display: block;
    overflow: hidden;
    user-select: none;
    width: fit-content;

    &::after {
      content: "";
      display: block;
      height: 1.4px;
      background: ${(props) => props.theme.colors.white};
      position: absolute;
      bottom: 6px;
      width: 100%;
      transform: translateX(-101%);
    }

    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      font-family: ${(props) => props.theme.fonts.body};
      text-transform: uppercase;
      font-weight: 400;
      font-size: 0.65vw;
      line-height: auto;
      letter-spacing: 0.1vw;
      margin: 0 20px;
      display: inline-block;
      &:hover {
        &:after {
          transition: transform 0.3s ${(props) => props.theme.animation.easing};
          transform: translateX(0%);
        }
      }
      &.animate-out {
        &::after {
          transition: transform 0.3s ${(props) => props.theme.animation.easing};
          transform: translateX(100%);
        }
      }
    }
  }
  a.active::after {
    transform: scale(1);
  }
`;

const HeaderNav = React.forwardRef(({ items, isMenuOpen, close }, ref) => {
  const main = useRef();
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const links = gsap.utils.toArray(".header__nav a");

      const onMouseLeave = (link) => {
        link.classList.add("animate-out");
        setTimeout(() => link.classList.remove("animate-out"), 300);
      };
      links.map((link) =>
        link.addEventListener("mouseleave", () => onMouseLeave(link))
      );

      const navAnimation = (direction) => {
        // console.log(direction);
        return gsap.to(links, {
          duration: 0.8,
          stagger: { each: 0.05, from: direction === 1 ? "start" : "end" },
          y: direction === 1 ? 20 : 1,
          opacity: direction === 1 ? 0 : 1,
          ease: "power2.out",
        });
      };

      ScrollTrigger.create({
        start: "100",
        end: "bottom bottom-=20",
        toggleClass: {
          targets: "body",
          className: "has-scrolled",
        },
        onEnter: ({ direction }) => navAnimation(direction),
        onLeaveBack: ({ direction }) => navAnimation(direction),
        // markers: true,
      });

      return () => {
        links.map((link) =>
          link.removeEventListener("mouseleave", () => onMouseLeave(link))
        );
      };
    }, main); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

  return (
    <StyledHeaderNav
      ref={ref}
      className={`header__nav ${isMenuOpen ? "nav--open" : ""}`}
    >
      <div ref={main}>
        {items.map((item) => (
          <Link
            activeClassName="active"
            to={item.slug}
            key={item.id}
            ref={item.ref}
            onClick={close}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </StyledHeaderNav>
  );
});

export default HeaderNav;
