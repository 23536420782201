import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import navigation from "../../data/navigation";
import config from "../../data/SiteConfig";

const StyledFooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
  /* padding: ${(props) => props.theme.sizes.paddingTopMobileContainer} 24px; */
  z-index: 12;
  /* @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    padding: ${(props) => props.theme.sizes.paddingTopContainer} 24px;
  } */

  .footer__outer {
    background-color: ${(props) => props.theme.colors.lightGreen};
    /* border-radius: ${(props) => props.theme.sizes.borderRadius}; */
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    /* max-width: ${(props) => props.theme.sizes.mediumMaxWidth}; */
    /* margin: 0 auto; */
    padding: 80px 24px;
    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      padding: 80px 64px;
    }
  }
  .footer__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 14px;
    font-weight: 400;
    line-height: 180%;
    margin-top: 3rem;
    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 20%;
      position: relative;
      margin-top: 0;
    }
    a {
      text-decoration: none;
      color: ${(props) => props.theme.colors.white};
      cursor: pointer;
    }
  }
  .footer__inner__logo {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 14px;
    font-weight: 400;
    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 40%;
      position: relative;
    }
    a {
      text-decoration: none;
      color: ${(props) => props.theme.colors.white};
      cursor: pointer;
    }
  }
  .footer__inner__social {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-family: ${(props) => props.theme.fonts.body};
    font-size: 14px;
    font-weight: 400;
    margin-top: 3rem;
    height: 100%;
    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 20%;
      position: relative;
      margin-top: 0;
      height: 300px;
    }
    a {
      text-decoration: none;
      color: ${(props) => props.theme.colors.white};
      cursor: pointer;
    }
    svg {
      fill: ${(props) => props.theme.colors.white};
      height: 24px;
    }

    .text-light {
      color: ${(props) => props.theme.colors.white};
      font-size: 10px;
      font-weight: 400;
      span {
        display: block;
        padding-bottom: 0.5rem;
      }
    }
  }
  .footer_inner__wrapper__social {
    display: flex;
    gap: 1rem;
    padding-bottom: 32px;
    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      padding-bottom: 197px;
    }
    a {
      text-decoration: none;
    }
  }
`;

const StyledHeaderLogo = styled.div`
  grid-column: 1/2;
  z-index: 11;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    grid-column: 1/1;
  }
  .headerLogo__inner {
    color: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    line-height: 1.4;
    font-size: 2.8vw;
    margin-bottom: 4rem;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      font-size: 0.8vw;
      letter-spacing: 0.05vw;
      margin-bottom: 9rem;
    }
    h2 {
      font-family: ${(props) => props.theme.fonts.headings};
      margin-left: 1rem;
      em {
        font-family: ${(props) => props.theme.fonts.headingsItalic};
      }
    }
  }

  svg {
    height: 42px;
    width: auto;
    stroke: ${(props) => props.theme.colors.white};
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      height: 48px;
      width: auto;
    }
  }
`;

const StyledContactDetails = styled.div`
  display: flex;
  flex-direction: column;

  .userCellPhone {
    margin-bottom: 0.5rem;
  }
  .userEmail {
    margin-bottom: 0.5rem;
  }
  a {
    text-decoration: none;
  }
`;

const Footer = () => {
  return (
    <StyledFooterWrapper>
      <div className="footer__outer">
        <div className="footer__inner__logo">
          <StyledHeaderLogo>
            <Link to="/">
              <div className="headerLogo__inner">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 59.528 48.189"
                >
                  <g
                    fill="none"
                    strokeWidth="1.4"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                  >
                    <path
                      d="M26.858,43.226c-6.288-6.23-9.369-16.381-7.221-25.407c-5.002-2.807-11.413-4.032-17.326-3.129
		c-2.397,15.597,9.351,30.291,24.829,31.587"
                    />
                    <path
                      d="M30.427,28.477c2.384-4.206,5.833-7.726,9.984-10.194c-1.372-6.714-5.172-12.542-10.44-16.533
		c-5.146,3.899-8.884,9.552-10.334,16.069C24.148,20.3,27.89,24.001,30.427,28.477z"
                    />
                    <path
                      d="M40.411,18.283c4.795-2.892,10.886-4.347,16.758-3.756c2.938,17.591-12.63,33.713-30.028,31.75
		C25.394,35.183,30.86,23.873,40.411,18.283"
                    />
                  </g>
                </svg>
                <h2>
                  Maureen Augras
                  <br />
                  <em>Sophrologue à Lille</em>
                </h2>
              </div>
            </Link>
          </StyledHeaderLogo>
          <StyledContactDetails>
            <a
              className="userCellPhone"
              target="_blank"
              rel="noopener noreferrer"
              href={config.userCellPhoneUrl}
            >
              {config.userCellPhone}
            </a>
            <a
              className="userEmail"
              target="_blank"
              rel="noopener noreferrer"
              href={config.userEmailUrl}
            >
              {config.userEmail}
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={config.userAddress.googleMaps}
            >
              {config.userAddress.street}
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={config.userAddress.googleMaps}
            >
              {config.userAddress.city}
            </a>
          </StyledContactDetails>
        </div>
        <div className="footer__inner">
          <ul>
            {navigation &&
              navigation.map((nav) => {
                return (
                  <li key={nav.id}>
                    <Link to={`${nav.slug}`}>{nav.name}</Link>
                  </li>
                );
              })}
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${config.userCalendyUrl}`}
              >
                {config.userCalendy}
              </a>
            </li>
          </ul>
        </div>
        <div className="footer__inner">
          <ul>
            {config.userBackLinks &&
              config.userBackLinks.map((backLink, index) => {
                return (
                  <li key={index}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${backLink.url}`}
                    >
                      {backLink.label}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="footer__inner__social">
          <div className="footer_inner__wrapper__social">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${config.Facebook}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
                <path
                  d="M400,200c0,99.83-73.14,182.57-168.75,197.57V257.81h46.6l8.87-57.81h-55.47v-37.52c0-15.81,7.75-31.23,32.59-31.23h25.22
		V82.03c0,0-22.89-3.91-44.77-3.91c-45.68,0-75.54,27.69-75.54,77.82V200h-50.78v57.81h50.78v139.76C73.14,382.57,0,299.83,0,200
		C0,89.54,89.54,0,200,0S400,89.54,400,200z"
                />
              </svg>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${config.Instagram}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
                <path
                  d="M200,36.036c53.402,0,59.728,0.204,80.817,1.166c19.5,0.89,30.09,4.148,37.137,6.887
		c9.335,3.628,15.998,7.962,22.996,14.961c6.999,6.999,11.333,13.661,14.961,22.996c2.739,7.047,5.997,17.638,6.887,37.137
		c0.962,21.09,1.166,27.415,1.166,80.817s-0.204,59.728-1.166,80.817c-0.89,19.5-4.148,30.09-6.887,37.137
		c-3.628,9.335-7.962,15.998-14.961,22.996c-6.999,6.999-13.661,11.333-22.996,14.961c-7.048,2.739-17.638,5.997-37.137,6.887
		c-21.086,0.962-27.411,1.166-80.817,1.166s-59.731-0.204-80.817-1.166c-19.5-0.89-30.09-4.148-37.137-6.887
		c-9.335-3.628-15.998-7.962-22.996-14.961c-6.999-6.998-11.333-13.661-14.961-22.996c-2.739-7.048-5.997-17.638-6.887-37.137
		c-0.962-21.09-1.166-27.415-1.166-80.817s0.204-59.728,1.166-80.817c0.89-19.5,4.148-30.09,6.887-37.137
		c3.628-9.335,7.962-15.998,14.961-22.996c6.999-6.999,13.661-11.333,22.996-14.961c7.047-2.739,17.638-5.997,37.137-6.887
		C140.272,36.24,146.598,36.036,200,36.036 M200,0c-54.317,0-61.128,0.23-82.46,1.203C96.252,2.175,81.714,5.556,68.992,10.5
		c-13.152,5.111-24.305,11.95-35.424,23.068C22.449,44.687,15.611,55.841,10.5,68.992c-4.944,12.722-8.325,27.26-9.296,48.548
		C0.23,138.872,0,145.683,0,200c0,54.317,0.23,61.128,1.203,82.46c0.971,21.288,4.352,35.826,9.296,48.548
		c5.111,13.151,11.95,24.305,23.068,35.424c11.119,11.119,22.273,17.957,35.424,23.068c12.722,4.944,27.26,8.325,48.548,9.296
		C138.872,399.77,145.683,400,200,400s61.128-0.23,82.46-1.203c21.288-0.971,35.826-4.352,48.548-9.296
		c13.152-5.111,24.305-11.95,35.424-23.068c11.119-11.119,17.957-22.273,23.068-35.424c4.944-12.722,8.325-27.26,9.296-48.548
		C399.77,261.128,400,254.317,400,200s-0.23-61.128-1.203-82.46c-0.971-21.288-4.352-35.826-9.296-48.548
		c-5.111-13.152-11.95-24.305-23.068-35.424c-11.119-11.119-22.273-17.957-35.424-23.068c-12.722-4.944-27.26-8.325-48.548-9.296
		C261.128,0.23,254.317,0,200,0L200,0z"
                />
                <path
                  d="M200,97.297c-56.721,0-102.703,45.982-102.703,102.703S143.279,302.703,200,302.703S302.703,256.721,302.703,200
		S256.721,97.297,200,97.297z M200,266.667c-36.819,0-66.667-29.848-66.667-66.667c0-36.819,29.848-66.667,66.667-66.667
		c36.819,0,66.667,29.848,66.667,66.667C266.667,236.819,236.819,266.667,200,266.667z"
                />
                <circle cx="306.76" cy="93.239" r="24" />
              </svg>
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${config.Twitter}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
                <path
                  d="M125.65,353.506c150.94,0,233.533-125.17,233.533-233.533c0-3.521,0-7.043-0.16-10.564
		C375.03,97.884,388.956,83.318,400,66.832c-14.726,6.563-30.572,10.884-47.219,12.965c16.967-10.084,29.932-26.251,36.174-45.458
		c-15.846,9.444-33.453,16.166-52.181,19.848c-15.046-16.006-36.335-25.93-59.864-25.93c-45.298,0-82.113,36.815-82.113,82.113
		c0,6.403,0.8,12.645,2.081,18.727c-68.187-3.361-128.691-36.174-169.188-85.794c-7.043,12.165-11.044,26.251-11.044,41.297
		c0,28.491,14.566,53.621,36.495,68.347c-13.445-0.48-26.09-4.162-37.135-10.244c0,0.32,0,0.64,0,1.12
		c0,39.696,28.331,72.989,65.786,80.512c-6.883,1.921-14.086,2.881-21.609,2.881c-5.282,0-10.404-0.48-15.366-1.441
		c10.404,32.653,40.816,56.343,76.671,56.983c-28.171,22.089-63.545,35.214-101.961,35.214c-6.563,0-13.125-0.32-19.528-1.12
		C36.174,339.901,79.392,353.506,125.65,353.506"
                />
              </svg>
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${config.Linkedin}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
                <path
                  d="M372.33,0H27.67C12.388,0,0,12.388,0,27.67V372.33C0,387.612,12.388,400,27.67,400H372.33
	c15.282,0,27.67-12.388,27.67-27.67V27.67C400,12.388,387.612,0,372.33,0z M121.289,340.242c0,1.805-1.463,3.268-3.268,3.268H64.054
	c-1.805,0-3.268-1.463-3.268-3.268V154.721c0-1.805,1.463-3.268,3.268-3.268h53.967c1.805,0,3.268,1.463,3.268,3.268V340.242z
	 M91.037,125.927c-19.174,0-34.718-15.544-34.718-34.718S71.863,56.49,91.037,56.49c19.174,0,34.718,15.544,34.718,34.718
	S110.212,125.927,91.037,125.927z M339.032,343.5l-50.895,0.01c-3.918,0.042-4.817-1.434-4.804-5.049l-0.051-94.281
	c-0.068-7.902-0.763-15.963-2.505-23.649c-3.095-13.657-12.074-20.411-26.014-20.57c-4.439-0.051-9.029,0.154-13.305,1.221
	c-13.842,3.455-19.721,13.95-21.996,26.868c-1.115,6.334-1.511,12.862-1.544,19.306l0.019,91.289c0.02,3.839-1.135,4.896-4.859,4.86
	l-50.896-0.012c-3.464,0.027-4.78-0.89-4.774-4.614l0.013-182.953c-0.005-3.378,0.919-4.509,4.423-4.472l49.398-0.006
	c3.606-0.041,4.451,1.252,4.359,4.541c-0.191,6.829-0.058,13.668-0.058,21.621c9.361-14.696,21.335-24.335,37.078-28.439
	c19.155-4.993,38.114-3.38,56.149,4.706c18.18,8.151,26.694,23.912,31.031,42.395c3.144,13.401,3.891,27.052,3.856,40.785
	l0.024,101.765C343.694,342.437,342.612,343.532,339.032,343.5z"
                />
              </svg>
            </a>
          </div>
          <p className="text-light">
            <span>
              Copyright &copy; {new Date().getFullYear()} Maureen Augras
              Sophrologue et Hypnothérapeute. Tous droits réservés.
            </span>
            <span></span>
            <span>
              <Link to="/mentions-legales/">Mentions légales</Link> |{" "}
              <Link to="/credits-photos/">Crédits photos</Link>
            </span>
          </p>
        </div>
      </div>
    </StyledFooterWrapper>
  );
};

export default Footer;
