const config = {
  siteTitle: "Maureen Augras, Sophrologue à Lille",
  siteUrl: "https://www.maureenaugras.com",
  userName: "Maureen AUGRAS",
  userEmail: "contact@maureenaugras.com",
  userEmailUrl: "mailto:contact@maureenaugras.com",
  userCalendy: "Prendre rendez-vous",
  userCalendyUrl: "https://calendly.com/sophrologue-lille",
  userCellPhone: "+33 6 30 26 83 69",
  userCellPhoneUrl: "tel:+33630268369",
  pathPrefix: "/",
  siteDescription:
    "Maureen Augras, sophrologue et hypnothérapeute à Lille, formée à l'INES à Paris, diplômée SFS. Gestion du stress, gestion des émotions, anxiété, angoisses, phobies, préparation à la maternité, trouble du sommeil ou de l'alimentation, confiance en soi, mieux-être, ...",
  userAddress: {
    name: "Lille",
    street: "104 Rue Royale",
    city: "59800 LILLE",
    googleMaps: "https://goo.gl/maps/Q7q3GLzgR68usMWr9",
  },
  Facebook:
    " https://www.facebook.com/people/Maureen-Augras-Sophrologue-Lille/100063581522223/ ",
  Instagram: "https://www.instagram.com/maureenaugras/",
  Twitter: "https://twitter.com/MaureenAugras",
  Linkedin: "https://www.linkedin.com/in/maureenaugrassophrologue",
  userBackLinks: [
    {
      label: "Syndicat des Sophrologues Professionnels",
      url: "https://www.syndicat-sophrologues-professionnels.fr/missions-du-ssp.html",
    },
    {
      label: "Société Française de Sophrologie",
      url: "https://www.sophrologie-francaise.com/",
    },
    {
      label: "Code de déontologie",
      url: "https://www.syndicat-sophrologues-professionnels.fr/le-metier-de-sophrologue.html",
    },
    {
      label: "Annuaire Santé",
      url: "https://www.annuairesante.com/sophrologue/lille-59000",
    },
    {
      label: "Hoodspot",
      url: "https://hoodspot.fr/autres-professionnels-de-sante/maureen-augras-sophrologue-et-hypnotherapeute-81753169200017/",
    },
    {
      label: "Proxi Bien être",
      url: "https://www.proxibienetre.fr/",
    },
    {
      label: "Je Suis Sophrologue",
      url: "https://www.jesuissophrologue.fr/",
    },
  ],
};

module.exports = config;
